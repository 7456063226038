import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M5430 7690 c-397 -4 -468 -6 -260 -8 284 -3 425 -6 720 -17 204 -8
262 -13 340 -31 14 -3 71 -12 128 -20 114 -16 151 -30 181 -72 48 -69 46 -158
-9 -278 -16 -37 -30 -75 -30 -84 0 -9 16 -48 36 -86 l36 -69 -6 -119 c-15
-267 -35 -282 -340 -251 -80 9 -113 30 -143 94 -38 81 -37 146 5 235 17 37 32
78 32 91 0 61 -158 116 -275 94 -33 -6 -73 -13 -90 -16 -37 -7 -55 -20 -55
-43 0 -9 -9 -25 -21 -36 -56 -50 -64 -115 -25 -185 35 -61 41 -114 22 -180
-14 -46 -15 -66 -5 -129 17 -113 24 -115 27 -7 2 89 4 97 22 97 19 0 20 7 20
130 0 94 -4 137 -14 153 -8 12 -17 38 -20 58 -6 31 -3 37 14 42 11 3 20 12 20
21 0 14 18 16 139 16 l139 0 6 -67 c3 -38 9 -92 12 -120 4 -36 2 -53 -5 -53
-7 0 -11 -41 -11 -125 l0 -124 -62 -31 c-92 -46 -166 -94 -267 -176 -2 -2 -8
-47 -13 -101 -17 -193 -54 -279 -135 -315 -38 -17 -201 -51 -303 -63 -14 -2
-39 -6 -57 -9 -84 -16 -113 -11 -148 25 -56 56 -90 212 -89 409 1 96 4 122 22
156 21 41 21 41 1 73 -23 39 -25 114 -4 194 8 32 15 71 15 86 0 32 -37 158
-48 165 -4 2 -8 -337 -9 -754 -2 -417 -3 -762 -4 -767 -1 -4 -1 -21 0 -38 1
-24 9 -13 39 61 46 110 73 149 115 168 28 14 135 39 222 53 17 3 72 16 124 29
118 30 159 23 194 -34 36 -57 46 -96 53 -201 3 -53 10 -111 16 -129 8 -28 14
-33 36 -30 15 2 26 8 25 13 -1 6 -9 47 -18 92 -30 148 -6 350 57 483 77 161
201 282 363 352 77 33 85 35 180 47 83 10 192 2 266 -20 38 -12 39 -13 39 -61
0 -47 -1 -49 -22 -42 -171 54 -339 43 -478 -33 -170 -92 -283 -248 -325 -448
-19 -88 -19 -152 0 -239 21 -98 45 -157 69 -166 16 -7 7 -9 -34 -10 -30 -1
-66 -5 -80 -9 -22 -6 -23 -8 -7 -14 10 -4 48 -14 85 -22 128 -30 156 -97 112
-264 -11 -41 -20 -87 -20 -101 0 -40 -40 -72 -97 -77 -26 -3 -104 0 -173 6
-444 39 -454 39 -697 40 l-211 1 -10 29 c-6 16 -8 32 -5 35 4 3 7 51 8 107 3
99 3 101 36 135 18 19 38 34 45 34 13 0 74 65 74 80 0 16 -202 35 -259 24
l-31 -6 0 -269 0 -269 586 0 587 0 50 -31 c44 -27 53 -29 76 -19 14 6 31 20
39 31 7 10 24 19 36 19 19 0 25 7 30 33 3 17 9 50 13 72 7 36 8 86 2 140 -1
11 -1 35 0 54 l1 34 25 -23 c21 -20 30 -22 53 -14 23 8 27 16 30 50 3 38 0 43
-49 80 -29 21 -60 55 -70 73 -10 19 -25 39 -33 43 -8 4 -28 42 -46 84 -37 89
-50 219 -31 318 33 179 179 339 356 393 76 23 227 20 298 -7 54 -20 57 -23 57
-56 l0 -34 49 0 c39 0 56 -6 81 -26 70 -60 135 -187 157 -311 8 -42 6 -55 -7
-73 -14 -19 -19 -20 -42 -10 -23 11 -27 20 -33 76 -19 183 -182 339 -375 359
-283 30 -517 -214 -469 -490 17 -101 44 -152 119 -227 67 -67 94 -80 118 -56
21 21 13 45 -25 71 -136 95 -181 291 -102 448 26 53 97 119 159 148 48 23 68
26 155 26 85 0 107 -4 148 -24 64 -31 138 -100 164 -153 29 -56 44 -160 33
-218 -6 -31 -5 -50 3 -60 10 -13 10 -13 -4 -3 -33 26 -179 -158 -179 -225 0
-7 11 -17 24 -23 13 -6 27 -21 31 -33 5 -17 9 -10 19 30 27 109 58 144 581
655 162 158 227 227 243 262 26 56 27 61 7 53 -12 -4 -13 -2 -6 13 6 10 17 23
25 31 22 17 20 30 -4 30 -12 0 -53 -32 -101 -80 -44 -44 -81 -80 -83 -80 -3 0
-15 17 -27 38 -13 20 -38 55 -56 78 -18 23 -33 44 -33 48 0 3 53 6 118 6 85 0
124 4 137 14 18 13 17 15 -16 32 -26 15 -55 19 -124 19 -78 0 -97 -4 -145 -28
-62 -31 -77 -48 -109 -129 -12 -31 -31 -64 -41 -73 -29 -26 -86 -35 -151 -23
-54 9 -62 14 -112 72 -30 33 -59 70 -65 82 -6 11 -17 69 -24 130 -13 108 -13
109 -18 49 -4 -48 -6 -37 -10 60 -5 113 -6 120 -26 123 l-21 3 3 427 c2 235 6
471 9 525 l6 98 -313 -3 c-172 -2 -558 -5 -858 -8z m1153 -1165 c12 -5 17 -18
17 -42 0 -34 -1 -35 -32 -29 -18 4 -80 9 -138 12 -235 13 -445 -76 -618 -260
-38 -42 -72 -76 -74 -76 -2 0 -2 15 0 33 2 17 -1 43 -6 57 -9 22 -4 30 42 73
125 119 303 206 481 237 55 10 300 6 328 -5z m629 -462 l33 -63 -41 -40 -41
-41 -21 59 c-16 42 -22 82 -22 138 l1 79 29 -35 c16 -19 44 -63 62 -97z m-190
-99 c21 -39 40 -88 43 -110 5 -34 1 -44 -29 -77 l-34 -38 -12 53 c-15 63 -47
138 -83 196 -15 23 -27 44 -27 47 0 3 24 5 53 3 l52 -3 37 -71z m-173 -381
c-43 -56 -163 -183 -172 -183 -8 0 -8 3 -2 8 6 4 23 22 38 41 23 28 26 36 15
50 -10 13 -10 14 2 4 17 -16 54 2 46 23 -3 8 -2 12 4 9 5 -3 24 10 42 30 35
37 49 46 27 18z"/>
<path d="M4051 7273 c1 -344 3 -398 13 -343 6 36 24 90 39 121 l27 57 -31 83
c-29 79 -31 93 -33 229 -1 134 1 148 21 181 36 58 56 63 273 69 l195 6 -252 2
-253 2 1 -407z"/>
<path d="M4588 7673 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M4688 7673 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4720 7210 c-64 -9 -138 -44 -151 -73 -8 -18 -7 -34 5 -68 10 -29 15
-36 16 -21 0 26 0 26 52 10 33 -10 40 -9 51 5 10 13 35 17 124 19 l113 3 0 45
c0 40 -4 48 -29 63 -33 19 -112 27 -181 17z"/>
<path d="M4572 6880 c-22 -239 -38 -255 -262 -255 -120 0 -151 3 -177 18 -39
21 -63 64 -73 132 -6 40 -8 29 -9 -62 l-1 -111 53 -13 c28 -8 95 -13 147 -13
52 1 173 2 268 2 l172 2 0 24 c0 28 -14 42 -53 51 -33 9 -34 11 -43 65 l-7 43
37 -7 c21 -4 44 -10 52 -13 11 -4 14 12 14 91 l0 96 -49 25 c-34 17 -51 32
-53 47 -2 13 -9 -42 -16 -122z"/>
<path d="M6655 6864 c-32 -9 -45 -34 -45 -86 0 -52 16 -66 23 -20 l4 27 2 -27
c1 -35 14 -36 35 -3 36 55 100 70 361 81 551 23 529 21 215 23 -157 0 -350 3
-430 6 -80 2 -154 2 -165 -1z"/>
<path d="M8395 6856 c31 -9 59 -25 77 -45 l28 -31 -4 -197 c-4 -217 -8 -236
-67 -310 -19 -23 -88 -89 -155 -146 -152 -132 -257 -246 -333 -364 -34 -51
-57 -92 -53 -92 9 2 326 334 419 439 36 41 101 112 145 157 l78 81 2 221 c1
122 2 238 3 259 l0 37 -95 2 -95 2 50 -13z"/>
<path d="M6313 5785 c-43 -26 -93 -107 -93 -149 0 -32 46 -123 69 -138 21 -13
21 -18 21 -531 0 -313 4 -525 10 -536 22 -43 111 -51 152 -14 17 15 18 46 18
538 l0 522 26 24 c89 83 82 205 -18 278 -55 41 -123 43 -185 6z"/>
<path d="M8250 5610 c-187 -4 -245 -8 -244 -17 1 -7 2 -34 3 -60 l1 -48 24 36
c37 54 72 64 206 62 63 -1 134 -7 158 -14 52 -14 98 -57 107 -100 11 -50 23
-20 27 66 3 74 2 80 -17 80 -11 1 -130 -2 -265 -5z"/>
<path d="M7900 5437 c0 -29 6 -36 41 -55 22 -12 43 -22 45 -22 2 0 4 20 4 44
0 40 -3 45 -31 55 -51 18 -59 14 -59 -22z"/>
<path d="M4912 5370 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6620 5132 c0 -39 -4 -52 -14 -52 -29 0 -35 -187 -8 -252 l12 -28
117 0 c113 0 119 -1 128 -22 6 -13 22 -27 37 -32 22 -8 34 -4 70 20 l43 29
755 0 755 1 2 119 c2 115 1 118 -11 72 -7 -27 -22 -61 -33 -75 -43 -59 -22
-57 -698 -56 -341 1 -705 -3 -808 -8 -267 -15 -290 -5 -302 122 -7 67 -32 200
-40 209 -3 2 -5 -19 -5 -47z"/>
<path d="M5329 4708 c-25 -21 -51 -69 -45 -84 4 -10 23 -14 66 -14 l60 0 0
-170 c0 -191 1 -194 68 -168 30 11 38 19 40 45 l3 31 37 -40 c28 -30 47 -41
79 -45 60 -8 88 2 139 49 l44 41 0 -47 c0 -41 2 -46 23 -46 12 0 37 9 55 20
l32 20 0 89 c0 92 12 120 45 108 11 -5 15 -30 17 -119 3 -108 4 -113 25 -116
12 -2 35 4 51 12 27 14 31 14 43 0 14 -17 49 -12 87 12 21 14 22 20 22 148 0
141 -7 163 -46 150 -11 -3 -4 3 14 15 26 17 32 27 32 55 0 70 -20 81 -77 46
-30 -18 -33 -24 -33 -70 0 -45 2 -50 23 -50 21 -1 21 -1 2 -12 -11 -6 -23 -16
-27 -21 -3 -6 -17 -2 -32 11 -35 27 -73 35 -113 23 -20 -6 -35 -6 -38 0 -9 14
-42 11 -75 -6 -22 -11 -30 -23 -30 -41 l0 -26 -39 35 c-71 64 -160 59 -225
-13 l-36 -40 0 60 0 60 44 0 c40 0 47 4 65 34 25 40 27 62 5 70 -31 12 -289 7
-305 -6z m291 -26 c0 -4 -7 -18 -16 -30 -12 -18 -25 -22 -65 -22 l-49 0 0
-165 c0 -94 -4 -165 -9 -165 -5 0 -17 -3 -26 -6 -14 -6 -16 10 -13 165 l3 171
-67 0 c-38 0 -68 2 -68 5 0 3 7 17 16 30 16 25 19 25 155 25 77 0 139 -3 139
-8z m570 -22 c0 -19 -7 -33 -19 -40 -31 -16 -41 -12 -41 18 0 20 7 32 23 40
33 16 37 15 37 -18z m-255 -120 c40 27 93 26 122 -2 21 -20 23 -30 23 -125 0
-102 0 -104 -26 -113 -14 -6 -27 -10 -30 -10 -2 0 -4 43 -4 96 0 114 -8 134
-55 134 -55 0 -65 -19 -65 -120 0 -78 -2 -91 -19 -100 -39 -20 -41 -14 -41
119 l0 129 28 10 c24 8 28 6 32 -14 l5 -24 30 20z m-191 5 c54 -41 73 -128 40
-184 -25 -42 -78 -73 -124 -73 -69 1 -120 56 -120 130 0 85 56 142 140 142 25
-1 54 -7 64 -15z m446 -110 c0 -125 0 -125 -26 -135 -14 -6 -27 -10 -30 -10
-2 0 -4 56 -4 125 0 121 1 126 23 135 12 5 25 9 30 9 4 1 7 -55 7 -124z"/>
<path d="M5601 4491 c-40 -94 82 -217 134 -137 35 53 -27 166 -90 166 -26 0
-35 -5 -44 -29z m82 -17 c42 -42 49 -124 12 -124 -28 0 -75 55 -75 88 0 67 21
78 63 36z"/>
<path d="M6567 4709 c-22 -13 -50 -67 -43 -85 4 -10 22 -14 61 -14 l55 0 0
-164 c0 -189 3 -197 69 -177 41 12 50 28 51 86 0 29 1 29 19 -12 21 -46 51
-69 99 -79 47 -9 78 2 128 48 l44 41 0 -42 c0 -28 5 -44 16 -48 20 -7 94 26
94 44 0 18 5 17 36 -12 54 -50 134 -44 190 13 l36 37 21 -29 c24 -34 73 -56
122 -56 26 0 45 9 75 37 l41 36 34 -33 c48 -47 116 -53 178 -16 25 14 47 33
51 41 9 25 26 8 26 -26 0 -39 24 -47 69 -23 l36 19 5 95 c4 80 8 95 23 98 15
3 17 -7 17 -108 0 -123 5 -133 61 -110 54 23 59 34 59 131 0 72 3 89 15 89 12
0 15 -18 15 -102 0 -124 6 -138 51 -121 21 8 38 9 50 3 10 -6 67 -10 128 -10
l110 0 25 33 c46 60 33 77 -60 77 -30 0 -54 2 -54 5 0 3 29 44 65 92 36 47 65
90 65 94 0 18 -43 29 -114 29 -73 0 -78 -2 -114 -35 l-39 -35 -11 24 c-16 37
-69 50 -123 32 -30 -10 -47 -12 -55 -5 -7 6 -48 12 -90 14 -88 4 -134 -12
-134 -45 0 -26 -12 -25 -39 4 -54 57 -144 59 -207 4 l-36 -31 -21 26 c-58 70
-162 61 -223 -19 l-28 -35 -10 33 c-7 22 -23 39 -46 52 -51 27 -103 16 -153
-35 -22 -21 -41 -39 -43 -39 -2 0 -4 47 -4 104 0 120 -6 128 -71 96 l-39 -20
0 -135 c0 -125 -1 -135 -19 -135 -10 0 -30 -9 -44 -20 -14 -11 -33 -20 -44
-20 -13 0 -10 5 13 19 67 41 89 94 60 150 -49 94 -195 48 -240 -74 l-15 -40 0
93 -1 92 43 0 c37 0 45 4 66 37 40 62 28 68 -140 70 -86 2 -151 -2 -162 -8z
m273 -49 c-19 -26 -27 -30 -71 -30 l-49 0 0 -164 c0 -169 -2 -176 -41 -176 -6
0 -9 66 -7 170 l3 170 -62 0 c-65 0 -72 5 -53 41 10 17 23 19 156 19 l145 0
-21 -30z m290 -160 l0 -190 -26 -10 c-14 -6 -27 -10 -30 -10 -2 0 -4 85 -4
188 0 186 0 189 23 200 12 6 25 11 30 11 4 1 7 -85 7 -189z m492 49 c23 -12
32 -47 18 -64 -18 -21 -29 -19 -60 15 -34 36 -53 38 -85 5 -34 -33 -33 -84 0
-123 35 -42 75 -49 125 -23 23 12 40 16 40 10 0 -18 -66 -79 -86 -80 -95 -6
-150 51 -142 143 11 105 103 162 190 117z m428 -10 c0 -20 0 -20 26 0 34 27
78 27 104 1 l21 -21 26 21 c31 24 80 26 105 3 15 -14 18 -32 18 -123 0 -105 0
-107 -25 -118 -14 -7 -28 -12 -30 -12 -3 0 -5 46 -5 103 0 104 -7 127 -39 127
-8 0 -23 -7 -33 -17 -15 -13 -18 -32 -18 -103 0 -83 -1 -87 -25 -98 -14 -7
-28 -12 -30 -12 -3 0 -5 47 -5 104 0 117 -10 137 -58 120 -26 -9 -27 -11 -30
-105 -3 -92 -4 -97 -28 -108 -14 -6 -27 -11 -29 -11 -3 0 -5 58 -5 128 0 124
1 129 23 139 27 13 37 8 37 -18z m-1070 -1 c11 -12 20 -32 20 -44 0 -31 -41
-77 -89 -99 -46 -20 -50 -29 -22 -45 30 -16 60 -12 98 12 35 21 35 21 24 1
-22 -42 -71 -73 -113 -73 -32 0 -47 7 -74 34 -29 29 -34 41 -34 80 0 56 18 94
60 130 41 34 100 36 130 4z m380 2 c31 -31 26 -73 -13 -107 -18 -16 -47 -35
-65 -42 -37 -15 -40 -26 -13 -41 30 -16 60 -12 98 12 35 21 35 21 24 1 -22
-42 -71 -73 -113 -73 -32 0 -47 7 -74 34 -28 28 -34 42 -34 75 0 55 20 101 59
134 41 34 101 37 131 7z m539 -5 c100 -85 36 -245 -98 -245 -80 0 -137 87
-112 169 6 22 27 53 46 70 48 42 118 44 164 6z m689 -7 c-13 -18 -48 -64 -76
-102 -29 -38 -52 -73 -52 -77 0 -5 36 -9 80 -11 l80 -3 -15 -22 c-15 -21 -24
-23 -116 -23 -54 0 -99 2 -99 5 0 3 34 49 75 103 l75 97 -65 3 c-36 2 -65 6
-65 9 0 4 10 17 22 30 19 20 31 23 101 23 l79 0 -24 -32z m-1012 -59 c16 -32
29 -34 78 -14 16 7 17 5 11 -16 -19 -67 -105 -92 -144 -43 -23 29 -26 55 -11
85 16 29 47 23 66 -12z m914 0 c0 -11 -87 -129 -96 -129 -2 0 -4 32 -4 70 l0
70 50 0 c29 0 50 -5 50 -11z m-1080 -38 c0 -15 -60 -61 -78 -60 -13 0 -11 4 8
17 13 9 31 25 40 34 17 18 30 22 30 9z"/>
<path d="M6844 4494 c-14 -20 -15 -33 -8 -61 11 -46 22 -49 65 -20 57 38 50
107 -12 107 -20 0 -34 -8 -45 -26z m76 -25 c0 -19 -38 -51 -52 -43 -13 8 -9
41 7 58 19 19 45 10 45 -15z"/>
<path d="M7232 4507 c-18 -21 -24 -62 -13 -90 13 -34 25 -34 68 -1 35 26 45
68 21 92 -16 16 -63 15 -76 -1z m63 -37 c0 -12 -10 -29 -22 -37 -22 -15 -23
-14 -23 14 0 31 17 56 35 50 5 -2 10 -14 10 -27z"/>
<path d="M7750 4501 c-5 -11 -10 -38 -10 -61 0 -34 6 -48 34 -76 36 -36 81
-45 104 -22 29 29 4 132 -39 162 -32 22 -76 21 -89 -3z m83 -25 c40 -37 50
-126 13 -126 -47 0 -96 99 -66 135 17 20 23 19 53 -9z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
